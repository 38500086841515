<template>
  <div class="item-container" v-loading="loading">
    <div class="line">
      <el-image
        class="item-logo"
        :src="require('@/assets/images/platform/icon_platforms1.png')"
      ></el-image>
      <el-image
        class="item1-logo"
        :src="require('@/assets/images/platform/icon_platforms2.png')"
      ></el-image>
      <el-image
        class="item-logo"
        :src="require('@/assets/images/platform/icon_platforms3.png')"
      ></el-image>
      <el-image
        class="item-logo"
        :src="require('@/assets/images/platform/icon_platforms4.png')"
      ></el-image>
      <el-image
        class="item2-logo"
        :src="require('@/assets/images/platform/icon_platforms5.png')"
      ></el-image>
      <el-image
        class="item2-logo"
        :src="require('@/assets/images/platform/icon_platforms6.png')"
      ></el-image>
      <el-image
        class="item3-logo"
        :src="require('@/assets/images/platform/icon_platforms7.png')"
      ></el-image>
      <el-image
        class="item-logo"
        :src="require('@/assets/images/platform/icon_platforms8.png')"
      ></el-image>
      <el-image
        class="item-logo"
        :src="require('@/assets/images/platform/icon_platforms9.png')"
      ></el-image>
      <el-image
        class="item2-logo"
        :src="require('@/assets/images/platform/icon_platforms10.png')"
      ></el-image>
      <el-image
        class="item-logo"
        :src="require('@/assets/images/platform/icon_platforms11.png')"
      ></el-image>
      <el-image
        class="item-logo"
        :src="require('@/assets/images/platform/icon_platforms12.png')"
      ></el-image>
    </div>

  </div>
</template>

<script lang="ts">
import { ref } from 'vue';
import { useRoute } from 'vue-router';
import { useI18n } from 'vue-i18n';

export default {
  name: 'platformItem',
  setup() {
    const loading = ref(false);
    const route = useRoute();
    const { t } = useI18n();

    const isExpand = ref(false);

    const troggleExpand = () => {
      isExpand.value = !isExpand.value;
    };

    return {
      loading,
      isExpand,
      troggleExpand
    };
  }
};
</script>

<style lang="scss" scoped>
.item-container {
  width: 100%;
  display: flex;
  overflow: hidden;
  white-space: nowrap;
  will-change: transform;

  .line {
    display: flex;
    height: 70px;
    animation: 10s move infinite linear;
  }

  .el-image {
    margin-right: 20px;
  }

  .item-logo {
    width: 178px;
    height: 70px;
  }

  .item1-logo {
    width: 164px;
    height: 70px;
  }

  .item2-logo {
    width: 120px;
    height: 70px;
  }

  .item3-logo {
    //2376
    //
    width: 142px;
    height: 70px;
  }

  @keyframes move {
    from {
      transform: translateX(0);
    }

    to {
      transform: translateX(-960px);
    }
  }
}
</style>
