<template>
  <div
    class="list-container"
    ref="homeContainer"
    v-loading.fullscreen.lock="loading"
    @scroll="handleScroll"
  >
    <div class="header" id="main-header">
      <div class="left-icon">
        <el-image
          class="logo"
          :src="require('@/assets/images/icon_brand.png')"
        ></el-image>
        <div class="name">
          {{ "LUCKY SHELL FINTECH LENDING, INC." }}
        </div>
      </div>
      <div class="right-menu">
        <div class="menu-group">
          <div class="menu-home" @click="handleHomeClick()">
            {{ "Home" }}
          </div>
          <div class="menu-divider"></div>
          <div class="menu-terms">
            <el-popover
              placement="bottom"
              :offset="30"
              :width="270"
              :show-arrow="false"
              trigger="hover"
              popper-class="terms-popover"
              content="this is content, this is content, this is content"
            >
              <template #reference>
                <div>{{ "Terms" }}</div>
              </template>
              <template #default>
                <div class="terms-container">
                  <div class="terms-item" @click="handlePolicyClick">
                    {{ "Privacy Policy" }}
                  </div>
                  <div class="terms-divider"></div>
                  <div class="terms-item" @click="handleRegistrationClick">
                    {{ "Registration Agreement" }}
                  </div>
                </div>
              </template>
            </el-popover>
          </div>
          <div class="menu-divider"></div>
          <div class="menu-account" @click="handleAccountDeletion">
            {{ "Account Deletion" }}
          </div>
          <div class="menu-divider"></div>
          <div class="menu-about-us" @click="handleAboutUsClick()">
            {{ "About us" }}
          </div>
          <div class="menu-divider"></div>
          <div class="menu-blog" @click="handleBlogClick()">
            {{ "Blog" }}
          </div>
        </div>
      </div>
    </div>
    <!--    List-->
    <div class="list-body">
      <el-image
        class="title-image"
        :src="require('@/assets/images/icon_aboutus.png')"
      ></el-image>

      <div class="body-main">
        <el-image
          class="body-bg"
          :src="require('@/assets/images/bg_aboutus.png')"
        ></el-image>

        <div class="body-header">
          <div class="component-title">

            <el-image
              class="license-image"
              :src="require('@/assets/images/icon_license_dark.png')"
            ></el-image>

            <div class="component-right" @click="handleQALinkClick">
              <el-image
                class="right-image"
                :src="require('@/assets/images/icon_right_light.png')"
              ></el-image>
              <div class="title-content">{{ "View Our Qualifications" }}</div>
            </div>
          </div>
        </div>

        <div class="body-content">
          {{ "We are the team of Lucky Peso, your trusted partner in providing convenient and reliable cash loan solutions. We understand that everyone faces unexpected financial challenges from time to time, and we are here to offer the support you need to overcome them.\n" +
        "\n" +
        "We prioritize responsible lending practices, ensuring that our loan products are tailored to meet your specific needs and financial circumstances. Our flexible repayment options allow you to choose a plan that aligns with your budget, making the repayment process manageable and stress-free.\n" +
        "\n" +
        "At Lucky Peso, we value your privacy and security. We employ stringent measures to safeguard your personal and financial information, using industry-standard encryption technology to protect your data. You can have peace of mind knowing that your information is safe with us.\n" +
        "\n" +
        "Thank you for choosing Lucky Peso. Let us be your financial partner, helping you navigate through life's unexpected challenges and achieve your goals." }}
        </div>
      </div>

      <el-image
        class="parner-image"
        :src="require('@/assets/images/icon_parner.png')"
      ></el-image>

      <div class="component-partner">
        <div class="partner-item">
          <el-image
            style="width: 194px; height: 66px; margin-bottom: 41px"
            :src="require('@/assets/images/icon_partner_skypay.png')"
          ></el-image>
          <div  style="width: 171px; height: 46px;">
            {{ "SKYPAY" }}
          </div>
        </div>
        <div class="partner-item">
          <el-image
            style="width: 171px; height: 46px; margin-bottom: 51px"
            :src="require('@/assets/images/icon_partner_paycools.png')"
          ></el-image>
          <div class="partner-text">
            {{ "PayCools" }}
          </div>
        </div>
        <div class="partner-item">
          <el-image
            style="width: 186px; height: 54px; margin-bottom: 47px"
            :src="require('@/assets/images/icon_partner_xendit.png')"
          ></el-image>
          <div class="partner-text">
            {{ "Xendit" }}
          </div>
        </div>
        <div class="partner-item">
          <el-image
            style="width: 182px; height: 56px; margin-bottom: 46px"
            :src="require('@/assets/images/icon_partner_payso.png')"
          ></el-image>
          <div class="partner-text">
            {{ "Payso" }}
          </div>
        </div>
      </div>
      <!--      Footer-->
      <div class="footer-divider"></div>
      <div class="footer-end">
        {{ 'LUCKY SHELL FINTECH LENDING, INC. © 2024 All Rights Reserved' }}
      </div>
    </div>
  </div>

  <el-dialog
    v-model="accountDeletionTipsDialogVisible"
    :show-close="false"
    class="account-deletion-dialog"
  >
    <div class="account-deletion-body">
      <div class="body-left">
        <div class="left-title">{{ "Account Deletion" }}</div>
        <div class="left-content">
          {{ "Please consider carefully before deleting your account." }}
        </div>
      </div>
      <div class="body-right">
        <div
          class="body-close"
          @click="accountDeletionTipsDialogVisible = false"
        >
          <el-image
            style="width: 16px; height: 16px"
            :src="require('@/assets/images/icon_close.png')"
          ></el-image>
        </div>
        <div class="right-title">
          {{ "1. Consequences You Should Be Aware Of:" }}
        </div>
        <div class="right-content">
          {{
            "1）Deleting your account will result in the permanent removal of all associated data, including persona information, loan history, and account details. Please weigh the implications before proceeding\n2）For deleted accounts, we have a 15-day freezing period. After this period can you re-register using the same mobile number."
          }}
        </div>
        <div class="right-title">
          {{
            "2. Before initiating account cancellation, please confirm that:"
          }}
        </div>
        <div class="right-content">
          {{
            "1）There are no unpaid loan orders associated with your account.\n2）No applications are in progress or awaiting disbursement."
          }}
        </div>
        <div class="right-action">
          <el-button
            class="confirm-button"
            @click="handleAccountDeletionContinue"
          >{{ "Continue" }}
          </el-button>
          <el-button
            class="cancel-button"
            @click="accountDeletionTipsDialogVisible = false"
          >{{ "Cancel" }}
          </el-button>
        </div>
      </div>
    </div>
  </el-dialog>

  <el-dialog v-model="smsDialogVisible" :show-close="false" class="sms-dialog" @closed="handleSmsDialogClose()">
    <div class="sms-dialog-body">
      <div
        class="body-close"
        @click="smsDialogVisible = false"
      >
        <el-image
          style="width: 16px; height: 16px"
          :src="require('@/assets/images/icon_close.png')"
        ></el-image>
      </div>
      <div class="body-title">Account Verification</div>
      <el-input
        class="body-phone-no"
        v-model="phoneNoInput"
        placeholder="Ex:9123456789"
        type="text"
        maxlength="10"
      >
        <template #prepend>+63(0)</template>
      </el-input>
      <div class="body-sms-container">
        <el-input
          class="body-phone-no"
          v-model="smsCodeInput"
          :disabled="!isCodeSendBtnEnable || !hasSendSmsCode"
          placeholder="Enter code"
          type="text"
          maxlength="6"
        >
        </el-input>
        <el-button
          class="sms-button"
          :disabled="!isCodeSendBtnEnable || sendCodeCountdown != 0"
          @click="handleSendSmsCode"
        >
          {{
            sendCodeCountdown
              ? `${sendCodeCountdown} s`
              : hasSendSmsCode
                ? "Resend Code"
                : "Send Code"
          }}
        </el-button>
      </div>
      <el-button
        class="confirm-button"
        :disabled="!isConfirmBtnEnable || !isCodeSendBtnEnable"
        @click="handleCheckSmsCode"
      >Confirm
      </el-button>
    </div>
  </el-dialog>

  <el-dialog
    v-model="commonErrorDialogVisible"
    width="30%"
    :show-close="false"
    class="common-error-dialog"
  >
    <div class="common-error-dialog-body">
      <div class="body-close" @click="commonErrorDialogVisible = false">
        <el-image
          style="width: 16px; height: 16px"
          :src="require('@/assets/images/icon_close.png')"
        ></el-image>
      </div>
      <div class="body-title">{{ errorTitle }}</div>
      <div class="body-content">{{ errorMessage }}</div>
      <el-button
        class="confirm-button"
        @click="commonErrorDialogVisible = false"
      >OK
      </el-button>
    </div>
  </el-dialog>

  <el-dialog
    v-model="resultDialogVisible"
    width="620"
    :show-close="false"
    class="result-dialog"
  >
    <div class="result-dialog-body">
      <div class="body-close" @click="resultDialogVisible = false">
        <el-image
          style="width: 16px; height: 16px"
          :src="require('@/assets/images/icon_close.png')"
        ></el-image>
      </div>
      <el-image
        style="width: 304px; height: 277px"
        :src="
          !accountDeletionResult
            ? require('@/assets/images/icon_deletion_failed.png')
            : require('@/assets/images/icon_deletion_success.png')
        "
      ></el-image>
      <div
        class="body-title"
        :class="{ 'failed-title': !accountDeletionResult }"
      >
        {{ errorTitle }}
      </div>
      <div class="body-content">{{ errorMessage }}</div>
      <el-button class="confirm-button" @click="resultDialogVisible = false"
      >OK
      </el-button>
    </div>
  </el-dialog>

</template>

<script lang="ts">
import { computed, onMounted, ref, watch } from "vue";
import router from "@/router";
import { useRoute } from "vue-router";
import FaqItem from "@/components/faqItem.vue";
import PlatformItem from "@/components/platformItem.vue";
import FooterItem from "@/components/footerItem.vue";
import { getPrivacyLink, getRegisterLink, GooglePlayLink, isReleaseForAndroid, isTestEnv } from "@/utils/orderConfig";
import {
  _accountDelete,
  _checkPhoneNext,
  _checkPhoneWeb,
  _login
} from "@/api/help";
import { generateUUID } from "@/utils";

export default {
  name: "aboutUsPage",
  components: { FooterItem, PlatformItem, FaqItem },
  setup() {
    const loading = ref(false);
    const loadingDialog = ref(false);
    const route = useRoute();

    const playFlip = ref(false);
    const accountDeletionTipsDialogVisible = ref(false);
    const smsDialogVisible = ref(false);
    const commonErrorDialogVisible = ref(false);

    const resultDialogVisible = ref(false);
    const accountDeletionResult = ref(false);

    const hasSendSmsCode = ref(false);
    const sendCodeCountdown = ref(0);
    const phoneNoInput = ref("");
    const smsCodeInput = ref("");

    const errorMessage = ref("");
    const errorTitle = ref("System Error");
    const homeContainer = ref(null);

    const placeFlag = computed(() => {
      return route.query.place;
    });

    const isCodeSendBtnEnable = computed(() => {
      const phoneRegex = /^9\d{9}$/;
      return (
        phoneNoInput.value.length === 10 && phoneRegex.test(phoneNoInput.value)
      );
    });

    const isConfirmBtnEnable = computed(() => {
      return (
        smsCodeInput.value.length === 6 && /^\d+$/.test(smsCodeInput.value)
      );
    });

    const play = () => {
      playFlip.value = !playFlip.value;
    };
    const handleAboutUsClick = () => {

    };
    const handleBlogClick = () => {
      router.replace({
        path: "blog"
      });
    };
    const handleScroll = (event: any) => {
      let scrollTop = event.target.scrollTop;

      const header = document.getElementById("main-header");
      if (scrollTop > 55) {
        header?.classList.add("scrolled");
      } else {
        header?.classList.remove("scrolled");
      }
    };

    const handlePolicyClick = () => {
      window.open(getPrivacyLink(), "_blank");
    };

    const handleRegistrationClick = () => {
      window.open(getRegisterLink(), "_blank");
    };

    const handleGooglePlayClick = () => {
      if (!isTestEnv()) {
        window.open(GooglePlayLink, "_blank");
      }

    };

    const handleAppStoreClick = () => {
      // window.open(AppStoreLink, '_blank')
    };

    const handleAccountDeletion = () => {
      accountDeletionTipsDialogVisible.value = true;
    };

    const handleAccountDeletionContinue = () => {
      accountDeletionTipsDialogVisible.value = false;
      sendCodeCountdown.value = 0;
      smsDialogVisible.value = true;
    };

    const handleSendSmsCode = () => {
      loading.value = true;
      let params = {};
      let formatPhoneNo = 0 + phoneNoInput.value;
      _checkPhoneWeb(params, formatPhoneNo, generateUUID(), {})
        .then((res: any) => {

          let isExist = res.isExist || 0;
          if (isExist == 0) {
            loading.value = false;
            errorTitle.value = "Notice";
            errorMessage.value = "The phone number you filled has not been registered yet.";
            commonErrorDialogVisible.value = true;
          } else if (isExist == 2) {
            loading.value = false;
            errorTitle.value = "Deletion Failed";
            errorMessage.value =
              "You have an unpaid or applying/disbursing order. Resolve the order and try again. For further information, reach out to our customer service.";
            accountDeletionResult.value = false;
            resultDialogVisible.value = true;
          } else {
            // success
            let wybs = res.wybs;
            _checkPhoneNext({}, formatPhoneNo, { "ss": wybs })
              .then((res: any) => {
                loading.value = false;
                hasSendSmsCode.value = true;
                sendCodeCountdown.value = 60;
                startCountdown();
              })
              .catch(err => {
                console.log("_checkPhoneNext", err);
                loading.value = false;
                if (err.code == "-1") {
                  accountDeletionResult.value = false;
                  resultDialogVisible.value = true;
                  errorTitle.value = "SMS verification reach limit";
                  errorMessage.value =
                    "The daily verification cap for this number has been reached. Please try again tomorrow.";
                } else {
                  showCommonErrorDialog();
                  commonErrorDialogVisible.value = true;
                }


              });
          }
        })
        .catch(err => {
          showCommonErrorDialog();
          commonErrorDialogVisible.value = true;
          loading.value = false;
        });
    };

    const startCountdown = () => {
      const time = setInterval(() => {
        sendCodeCountdown.value -= 1;
        if (sendCodeCountdown.value <= 0) {
          clearInterval(time);
        }
      }, 1000);
    };

    const handleSmsDialogClose = () => {

    };

    const resetSmsInput = () => {
      hasSendSmsCode.value = false;
      sendCodeCountdown.value = 0;
      phoneNoInput.value = "";
      smsCodeInput.value = "";
    };

    const showCommonErrorDialog = () => {
      errorTitle.value = "System Error";
      errorMessage.value =
        "Error detected. We're taking measures to fix this. Please try your request again later.";
    };

    const handleCheckSmsCode = () => {
      loading.value = true;
      let formatPhoneNo = 0 + phoneNoInput.value;
      let params = {};

      _login(params, formatPhoneNo, smsCodeInput.value, {})
        .then((res: any) => {
          console.log("_login", res);
          let token = res.item.token;

          let params = {};
          _accountDelete(params, { token: token })
            .then((res: any) => {
              loading.value = false;
              smsDialogVisible.value = false;
              errorTitle.value = "Successful";
              errorMessage.value = res.message;
              accountDeletionResult.value = true;
              resultDialogVisible.value = true;

              resetSmsInput();
            })
            .catch(err => {
              showCommonErrorDialog();
              commonErrorDialogVisible.value = true;
              loading.value = false;
            });
        })
        .catch(err => {
          if (err.code == "-1") {
            errorTitle.value = "Notice";
            errorMessage.value =
              "Wrong verification code, please check and enter it again";
          } else {
            showCommonErrorDialog();
          }

          commonErrorDialogVisible.value = true;
          loading.value = false;
        });
    };

    const handleQALinkClick = () => {
      window.open("https://www.sec.gov.ph/lending-companies-and-financing-companies-2/list-of-recorded-online-lending-platforms/#gsc.tab=0&gsc.sort=", "_blank");
    };

    const handleHomeClick = () => {
      router.push({
        path: "/"
      });
    }

    watch(
      placeFlag, val => {
        if (val == "deletion") {
          handleAccountDeletion();
        }
      },
      { immediate: true }
    );

    watch(
      accountDeletionTipsDialogVisible, val => {
        if (val) {
          if (placeFlag.value != "deletion") {
            router.push({
              path: route.path,
              query: { place: "deletion" }
            });
          }

        } else {
          router.push(route.path);
        }
      },
      { immediate: false }
    );

    onMounted(() => {

    });

    return {
      loading,
      loadingDialog,
      playFlip,
      homeContainer,
      accountDeletionTipsDialogVisible,
      smsDialogVisible,
      errorTitle,
      errorMessage,
      resultDialogVisible,
      accountDeletionResult,
      commonErrorDialogVisible,
      phoneNoInput,
      smsCodeInput,
      isCodeSendBtnEnable,
      isConfirmBtnEnable,
      hasSendSmsCode,
      sendCodeCountdown,
      handleSmsDialogClose,
      handleSendSmsCode,
      handleCheckSmsCode,
      handleAccountDeletionContinue,
      handleAccountDeletion,
      handlePolicyClick,
      handleRegistrationClick,
      handleGooglePlayClick,
      handleAppStoreClick,
      handleAboutUsClick,
      handleBlogClick,
      handleScroll,
      handleQALinkClick,
      handleHomeClick,
      isReleaseForAndroid
    };
  }
};
</script>

<style lang="scss" scoped>
.list-container {
  height: 100vh;
  width: calc(100% - 240px);
  padding: 0 120px;
  margin: 0 auto;
  position: relative;
  overflow-x: hidden;
  overflow-y: auto;

  .header {
    position: fixed;
    z-index: 10;
    width: calc(1440px - 240px);
    height: 110px;
    transition: all 0.4s;
    background-color: transparent;

    display: flex;
    align-items: center;

    .left-icon {
      display: flex;
      align-items: center;

      .logo {
        width: 50px;
        height: 50px;
      }
    }

    .right-menu {
      flex: 1;

      display: flex;
      justify-content: end;
      align-items: center;

      .menu-group {
        margin: 0;
        height: 50px;
        border-radius: 25px;
        border: 1px solid #4a4a4a;

        font-size: 22px;
        font-weight: 600;
        color: #333333;
        line-height: 50px;

        display: flex;
        align-items: center;

        div {
          cursor: pointer;
        }

        .menu-divider {
          height: 21px;
          border-left: 1px solid #4a4a4a;
        }

        .menu-home {
          padding: 0 27px 0 37px;
        }

        .menu-terms {
          padding: 0 31px 0 30px;
        }

        .menu-account {
          padding: 0 20px 0 19px;
        }

        .menu-about-us {
          color: #5669E4;
          padding: 0 30px 0 30px;
        }

        .menu-blog {
          padding: 0 40px 0 30px;
        }
      }
    }
  }

  #main-header.scrolled {
    background-color: #FFFFFF;
  }

  .list-body {
    padding-top: 185px;

    display: flex;
    flex-direction: column;
    align-items: center;

    .title-image {
      width: 262px;
      height: 68px;
    }

    .parner-image {
      width: 307px;
      height: 68px;
    }

    .body-main {
      margin-top: 51px;
      margin-bottom: 80px;
      width: calc(100% - 80px);
      padding: 40px;
      padding-bottom: 147px;

      position: relative;

      .body-bg {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
      }

      .body-header {
        background-color: #F9F9F9;
        border-radius: 45px;
        padding: 45px 0;

        .component-title {
          display: flex;
          align-items: center;
          justify-content: space-between;


          .license-image {
            margin-left: 60px;
            width: 522px;
            height: 101px;
          }

          .component-right {
            display: flex;
            align-items: center;
            margin-right: 35px;
            z-index: 100;

            cursor: pointer;

            .right-image {
              margin-right: 25px;
              width: 56px;
              height: 41px;
            }

            .title-content {

              font-weight: bold;
              font-size: 30px;
              color: #000000;
              line-height: 37px;
              text-align: left;
              font-style: normal;
              text-decoration-line: underline;
            }

          }
        }
      }

      .body-content {
        margin-top: 40px;

        font-weight: bold;
        font-size: 26px;
        color: #333333;
        line-height: 56px;
        text-align: left;
        font-style: normal;
        white-space: pre-line;
      }
    }

    .component-partner {
      margin-top: 50px;

      width: 100%;
      height: 248px;
      display: flex;
      align-items: end;

      .partner-item {
        flex: 1;
        height: 100%;
        border: 2px solid #000000;
        border-radius: 45px;

        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        font-weight: bold;
        font-size: 26px;
        color: #333333;
        line-height: 32px;
        text-align: center;
        font-style: normal;

        .partner-logo {
          width: 194px;
          height: 66px;
        }

      }

      .partner-item:not(:last-child) {
        margin-right: 50px;
      }
    }

    .footer-divider {
      margin-top: 80px;
      width: 100%;
      border-bottom: 1px solid #333333;
    }

    .footer-end {
      margin: 30px 30px 80px;

      font-weight: 500;
      font-size: 22px;
      color: #333333;
      line-height: 30px;
      text-align: left;
      font-style: normal;
    }
  }

  .name {
    margin-left: 20px;

    font-size: 20px;
    font-weight: 600;
    color: #333333;
    line-height: 50px;
  }
}
</style>