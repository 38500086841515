<template>
  <div class="footer-container">
    <div class="footer-header">
      <div class="footer-left">
        <div class="footer-title">
          {{ 'Working Time' }}
        </div>
        <div class="footer-content">
          {{ 'Mondays to Fridays 9:00 AM - 6:00 PM' }}
        </div>

        <div class="footer-title">
          {{ 'Company Name' }}
        </div>
        <div class="footer-content">
          {{ 'LUCKY SHELL FINTECH LENDING, INC.' }}
        </div>

        <div class="footer-title">
          {{ 'Company Address' }}
        </div>
        <div class="footer-content">
          {{
            '9th Floor, Philam Life Tower, \n8767 Paseo de Roxas, Salcedo Village, Bel-Air 1226, \nCity of Makati NCR, Fourth District Philippines'
          }}
        </div>

        <div class="footer-title">
          {{ 'Terms' }}
        </div>
        <div class="footer-content">
          <a class="text-privacy" target="_blank" :href="getPrivacyLink()">{{$t('common.common_privacy')}}</a>
          <a class="text-privacy" style="margin-left: 30px" target="_blank" :href="getRegisterLink()">{{$t('common.common_registration')}}</a>
        </div>
      </div>
      <div class="footer-right">

        <el-image
          v-if="ggLinkDisable"
          class="icon-google-disable"
          :src="require('@/assets/images/icon_googleplay.png')"
        ></el-image>

        <div class="icon-apple-container" v-else>
          <el-image
            class="icon-apple"
            :src="require('@/assets/images/icon_gg_enable.png')"
          ></el-image>

          <el-image
            class="icon-download"
            :src="require('@/assets/images/icon_download.png')"
          ></el-image>

          <a class="text-download" target="_blank" :href="GooglePlayLink()">
            {{ 'Download' }}
          </a>
        </div>

        <el-image
          v-if="appleLinkDisable"
          class="icon-apple-disable"
          :src="require('@/assets/images/icon_apple_disable.png')"
        ></el-image>

        <div class="icon-apple-container" v-else>
          <el-image
            class="icon-apple"
            :src="require('@/assets/images/icon_apple.png')"
          ></el-image>

          <el-image
            class="icon-download"
            :src="require('@/assets/images/icon_download.png')"
          ></el-image>

          <a class="text-download" target="_blank" :href="AppStoreLink()">
            {{ 'Download' }}
          </a>
        </div>

        <div class="email-container">
          <el-image
            class="icon-email"
            :src="require('@/assets/images/icon_email.png')"
          ></el-image>

          <div class="text-email">
            {{ 'E-mail' }}
          </div>

          <a
            class="text-download"
            target="_blank"
            :href="'mailto:' + EmailLink()"
          >
            {{ EmailLink() }}
          </a>
        </div>

        <div class="tel-container">
          <el-image
            class="icon-tel"
            :src="require('@/assets/images/icon_tel.png')"
          ></el-image>

          <div class="text-tel">
            {{ 'Phone' }}
          </div>

          <div class="text-download">
            {{ TelLink() }}
          </div>
        </div>

        <div class="fb-container" :class="{ disableContainer: fbDisable }">
          <el-image
            class="icon-fb"
            :src="require('@/assets/images/icon_facebook.png')"
          ></el-image>

          <div class="text-fb">
            {{ 'FaceBook' }}
          </div>

          <a class="text-download" target="_blank" :href="FacebookLink()">
            {{ FacebookLink() }}
          </a>
        </div>
      </div>
    </div>
    <div class="footer-divider"></div>
    <div class="footer-end">
      {{ 'LUCKY SHELL FINTECH LENDING, INC. © 2024 All Rights Reserved' }}
    </div>
  </div>
</template>

<script lang="ts">
import { ref } from 'vue';
import { useRoute } from 'vue-router';
import { useI18n } from 'vue-i18n';
import {
  AppStoreLink,
  GooglePlayLink,
  EmailLink,
  TelLink,
  FacebookLink, getPrivacyLink, getRegisterLink
} from "@/utils/orderConfig";

export default {
  name: 'footerItem',
  methods: {
    getRegisterLink,
    getPrivacyLink,
    AppStoreLink() {
      return AppStoreLink;
    },
    GooglePlayLink() {
      return GooglePlayLink;
    },
    EmailLink() {
      return EmailLink;
    },
    TelLink() {
      return TelLink;
    },
    FacebookLink() {
      return FacebookLink;
    }
  },
  setup() {
    const loading = ref(false);
    const route = useRoute();
    const { t } = useI18n();

    const fbDisable = ref(true);
    const appleLinkDisable = ref(true);
    const ggLinkDisable = ref(false);

    return {
      loading,
      fbDisable,
      appleLinkDisable,
      ggLinkDisable
    };
  }
};
</script>

<style lang="scss" scoped>
.footer-container {
  width: calc(100% + 240px);
  margin-top: 100px;
  padding-top: 40px;
  background: #141414;

  display: flex;
  flex-direction: column;

  .footer-header {
    display: flex;

    .footer-left {
      flex: 1;
      padding-left: 120px;
      text-align: left;

      .footer-title {
        margin-top: 50px;

        font-size: 24px;
        font-weight: 500;
        color: #ffffff;
        line-height: 28px;
      }

      .footer-content {
        margin-top: 26px;

        white-space: pre-line;

        font-size: 22px;
        font-weight: 500;
        color: #ffffff;
        line-height: 30px;

        .text-privacy {
          font-size: 22px;
          font-weight: 500;
          color: #FFFFFF;
          line-height: 36px;
        }
      }
    }

    .footer-right {
      margin-top: 55px;
      margin-right: 120px;

      display: flex;
      flex-direction: column;
      align-items: end;

      .icon-name {
        margin-right: 40px;
        width: 126px;
        height: 24px;
      }

      .icon-google-disable {
        margin-top: 34px;
        cursor: not-allowed;
        width: 192px;
        height: 64px;
      }

      .icon-apple-disable {
        margin-top: 43px;
        cursor: not-allowed;
        width: 192px;
        height: 64px;
      }

      .icon-apple-container {
        margin-top: 43px;
        display: flex;
        align-items: center;
        background: #000000;
        border-radius: 32px;
        border: 2px solid transparent;

        .icon-apple {
          width: 192px;
          height: 64px;
        }

        .icon-download {
          width: 32px;
          height: 30px;
          display: none;
        }

        .text-download {
          cursor: pointer;
          margin-left: 14px;
          margin-right: 30px;
          display: none;
          transform: translateX(100%);
          transition: transform 0.3s;

          font-size: 24px;
          font-weight: 500;
          color: #ffffff;
          line-height: 33px;
          text-decoration: underline;
        }
      }

      .icon-apple-container:hover {
        border: 2px solid #5669e4;

        .icon-download {
          display: block;
        }

        .text-download {
          transform: translateX(0);
          display: block;
        }
      }

      .email-container {
        margin-top: 43px;
        display: flex;
        align-items: center;
        justify-content: center;
        background: #000000;
        border: 2px solid transparent;
        border-radius: 33px;

        min-width: 132px;
        padding: 0 30px;
        height: 64px;

        .icon-email {
          width: 36px;
          height: 29px;
        }

        .text-email {
          margin-left: 15px;
          font-size: 24px;
          font-weight: 500;
          color: #ffffff;
          line-height: 33px;
        }

        .text-download {
          cursor: pointer;
          margin-left: 14px;
          display: none;
          transform: translateX(100%);
          transition: transform 0.3s;

          font-size: 24px;
          font-weight: 500;
          color: #ffffff;
          line-height: 33px;
          text-decoration: underline;
        }
      }

      .email-container:hover {
        background: #000000;
        border-radius: 33px;
        border: 2px solid #5669e4;

        .icon-download {
          display: block;
        }

        .text-download {
          transform: translateX(0);
          display: block;
        }
      }

      .tel-container {
        margin-top: 43px;
        display: flex;
        align-items: center;
        justify-content: center;
        background: #000000;
        border: 2px solid transparent;
        border-radius: 33px;

        min-width: 132px;
        padding: 0 30px;
        height: 64px;

        .icon-tel {
          width: 32px;
          height: 33px;
        }

        .text-tel {
          margin-left: 18px;
          font-size: 24px;
          font-weight: 500;
          color: #ffffff;
          line-height: 33px;
        }

        .text-download {
          cursor: pointer;
          margin-left: 14px;
          display: none;
          transform: translateX(100%);
          transition: transform 0.3s;

          font-size: 24px;
          font-weight: 500;
          color: #ffffff;
          line-height: 33px;
          text-decoration: underline;
        }
      }

      .tel-container:hover {
        border: 2px solid #5669e4;

        .text-download {
          transform: translateX(0);
          display: block;
        }
      }

      .fb-container {
        margin-top: 43px;
        display: flex;
        align-items: center;
        justify-content: center;
        background: #000000;
        border-radius: 33px;

        min-width: 132px;
        padding: 0 30px;
        height: 64px;

        .icon-fb {
          width: 21px;
          height: 38px;
        }

        .text-fb {
          margin-left: 18px;
          font-size: 24px;
          font-weight: 500;
          color: #ffffff;
          line-height: 33px;
        }

        .text-download {
          cursor: pointer;
          margin-left: 14px;
          display: none;
          transform: translateX(100%);
          transition: transform 0.3s;

          font-size: 24px;
          font-weight: 500;
          color: #ffffff;
          line-height: 33px;
          text-decoration: underline;
        }
      }

      .disableContainer {
        background: #6a6d6d;
        cursor: not-allowed;
      }

      .fb-container:hover:not(.disableContainer) {
        border: 2px solid #5669e4;

        .text-download {
          transform: translateX(0);
          display: block;
        }
      }
    }
  }

  .footer-divider {
    margin: 49px 120px 0;
    border-bottom: 1px solid #333333;
  }

  .footer-end {
    margin: 30px 30px 40px;
    font-size: 22px;
    font-weight: 500;
    color: #ffffff;
    line-height: 30px;
  }
}
</style>