import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, resolveDirective as _resolveDirective, openBlock as _openBlock, createElementBlock as _createElementBlock, withDirectives as _withDirectives, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-1da3614e"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "item-container" }
const _hoisted_2 = { class: "line" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_image = _resolveComponent("el-image")!
  const _directive_loading = _resolveDirective("loading")!

  return _withDirectives((_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_el_image, {
        class: "item-logo",
        src: require('@/assets/images/platform/icon_platforms1.png')
      }, null, 8, ["src"]),
      _createVNode(_component_el_image, {
        class: "item1-logo",
        src: require('@/assets/images/platform/icon_platforms2.png')
      }, null, 8, ["src"]),
      _createVNode(_component_el_image, {
        class: "item-logo",
        src: require('@/assets/images/platform/icon_platforms3.png')
      }, null, 8, ["src"]),
      _createVNode(_component_el_image, {
        class: "item-logo",
        src: require('@/assets/images/platform/icon_platforms4.png')
      }, null, 8, ["src"]),
      _createVNode(_component_el_image, {
        class: "item2-logo",
        src: require('@/assets/images/platform/icon_platforms5.png')
      }, null, 8, ["src"]),
      _createVNode(_component_el_image, {
        class: "item2-logo",
        src: require('@/assets/images/platform/icon_platforms6.png')
      }, null, 8, ["src"]),
      _createVNode(_component_el_image, {
        class: "item3-logo",
        src: require('@/assets/images/platform/icon_platforms7.png')
      }, null, 8, ["src"]),
      _createVNode(_component_el_image, {
        class: "item-logo",
        src: require('@/assets/images/platform/icon_platforms8.png')
      }, null, 8, ["src"]),
      _createVNode(_component_el_image, {
        class: "item-logo",
        src: require('@/assets/images/platform/icon_platforms9.png')
      }, null, 8, ["src"]),
      _createVNode(_component_el_image, {
        class: "item2-logo",
        src: require('@/assets/images/platform/icon_platforms10.png')
      }, null, 8, ["src"]),
      _createVNode(_component_el_image, {
        class: "item-logo",
        src: require('@/assets/images/platform/icon_platforms11.png')
      }, null, 8, ["src"]),
      _createVNode(_component_el_image, {
        class: "item-logo",
        src: require('@/assets/images/platform/icon_platforms12.png')
      }, null, 8, ["src"])
    ])
  ])), [
    [_directive_loading, $setup.loading]
  ])
}