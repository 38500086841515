import axios from 'axios';
import i18n from '@/assets/lang/index';
import { useI18n } from 'vue-i18n';

let enMessages = i18n.global.messages.value.en;
let errorObject = enMessages['error'];
let errCode = Object.keys(errorObject);
const service = axios.create({
  baseURL: process.env.BASE_API, // api 的 base_url
  timeout: 60000, // request timeout
  responseType: 'json',
});

let pending: Array<any> = []; // 网络请求记录
let cancelToken = axios.CancelToken;
let removePending = (config: any) => {
  for (let p in pending) {
    let paramsData: any;
    if (
      config.method == 'post' &&
      config.headers['Content-Type'] != 'multipart/form-data'
    ) {
      paramsData = { ...config.data };
      delete paramsData._t;
    } else if (config.method == 'get') {
      paramsData = { ...config.params };
      delete paramsData._t;
    }
    if (
      pending[p].u ===
      config.url + '&' + JSON.stringify(paramsData) + '&' + config.method
    ) {
      pending[p].f('CancelToken');
      pending.splice(Number(p), 1);
      break;
    }
  }
};
let reqNum = 0;
let reqNumChange = () => {
  reqNum--;
  if (reqNum <= 0) {
    pending = [];
    reqNum = 0;
  }
};

function startLoading() {}

function endLoading() {}

function getQueryVariable(variable: string) {
  let pathname = window.location.href;
  let str = pathname.split('?');
  let query = str[1];
  if (!query) return false;
  let vars = query.split('&');
  for (let i = 0; i < vars.length; i++) {
    if (vars[i]) {
      let pair = vars[i].split('=');
      if (pair[0] == variable) {
        return pair[1];
      }
    }
  }
  return false;
}

// 请求拦截  设置统一header
service.interceptors.request.use(
  config => {
    // 加载
    startLoading();
    // config.headers.token = getCookie();
    // config.headers.credential = getCredential();
    config.headers.deviceId = 'WEB';
    config.headers.simulator = '1';
    config.headers.credential = '008e1e51-4b6e-41f4-9ecf-98dbf914fb05';

    config.headers.setContentType('application/json');


    removePending(config);
    let paramsData: any;
    if (
      config.method == 'post' &&
      config.headers['Content-Type'] == 'text/plain'
    ) {
      paramsData = { ...config.data };
      console.log('request', config.data);
      // config.data = encrypt(config.data);
    } else if (config.method == 'get') {
      paramsData = { ...config.params };
      config.params = {
        _t: Date.now(),
        ...config.params
      };
    }
    config.cancelToken = new cancelToken(c => {
      pending.push({
        u: config.url + '&' + JSON.stringify(paramsData) + '&' + config.method,
        f: c
      });
    });
    reqNum++;
    return config;
  },
  error => {
    console.log(error);
    return Promise.reject(error);
  }
);

// 响应拦截  401 token过期处理
service.interceptors.response.use(
  res => {
    endLoading();
    reqNumChange();
    let url = res.config.url;
    if (url && url.includes('resource')) {
      // 兼容文件资源查看
      return res;
    }

    let result = res.data;

    //success
    if (['1', '0'].includes(result.code)) {

      return {...(result.data), message: result.message};
    }

    let _errCode = result.code;
    let reject = {
      ...res.data,
      errMsg: result.message || _errCode
    };

    let langErrMsg = errCode.filter(key => key === result.code)[0];
    if (langErrMsg) {
      const { t } = useI18n({
        inheritLocale: true
      });
      let localeMessages: any = t('error');
      _errCode = localeMessages[langErrMsg];
    } else {
      _errCode = result.message;
    }

    return Promise.reject(reject);
  },
  error => {
    // 错误提醒
    endLoading();
    reqNumChange();
    let message = i18n.global.t('error.common_network_error');
    return Promise.reject(error);
  }
);

export default service;
