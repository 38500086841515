<template>
  <div
    class="list-container"
    ref="homeContainer"
    v-loading.fullscreen.lock="loading"
    @scroll="handleScroll"
  >
    <div class="header" id="main-header">
      <div class="left-icon">
        <el-image
          class="logo"
          :src="require('@/assets/images/icon_brand.png')"
        ></el-image>
        <div class="name">
          {{ "LUCKY SHELL FINTECH LENDING, INC." }}
        </div>
      </div>
      <div class="right-menu">
        <div class="menu-group">
          <div class="menu-terms">
            <el-popover
              placement="bottom"
              :offset="30"
              :width="270"
              :show-arrow="false"
              trigger="hover"
              popper-class="terms-popover"
              content="this is content, this is content, this is content"
            >
              <template #reference>
                <div>{{ "Terms" }}</div>
              </template>
              <template #default>
                <div class="terms-container">
                  <div class="terms-item" @click="handlePolicyClick">
                    {{ "Privacy Policy" }}
                  </div>
                  <div class="terms-divider"></div>
                  <div class="terms-item" @click="handleRegistrationClick">
                    {{ "Registration Agreement" }}
                  </div>
                </div>
              </template>
            </el-popover>
          </div>
          <div class="menu-divider"></div>
          <div class="menu-account" @click="handleAccountDeletion">
            {{ "Account Deletion" }}
          </div>
          <div class="menu-divider"></div>
          <div class="menu-about-us" @click="handleAboutUsClick()">
            {{ "About us" }}
          </div>
          <div class="menu-divider"></div>
          <div class="menu-blog" @click="handleBlogClick()">
            {{ "Blog" }}
          </div>
        </div>
      </div>
    </div>
    <!--    List-->
    <div class="list-body">
      <el-image
        class="title-image"
        :src="isReleaseForAndroid ? require('@/assets/images/icon_title_android.png') : require('@/assets/images/icon_title.png')"
      ></el-image>
      <el-image
        class="bank-image"
        :src="require('@/assets/images/icon_bank.png')"
      ></el-image>
      <div class="brief-component">
        <div class="brief-item">
          <div class="brief-title">
            {{ "80,000 pesos" }}
          </div>
          <div class="brief-content">
            {{ "Maximum Loan Amount" }}
          </div>
        </div>
        <div class="brief-item">
          <div class="brief-item">
            <div class="brief-title">
              {{ "125 days" }}
            </div>
            <div class="brief-content">
              {{ "Loan Term over four months" }}
            </div>
          </div>
        </div>
        <div class="brief-item">
          <div class="brief-item">
            <div class="brief-title">
              {{ "2%" }}
            </div>
            <div class="brief-content">
              {{ "Low Interest Rate" }}
            </div>
          </div>
        </div>
        <div class="download-button-new">
          <el-popover
            placement="bottom"
            :offset="30"
            :width="270"
            :show-arrow="false"
            trigger="hover"
            popper-class="terms-popover"
            content="this is content, this is content, this is content"
          >
            <template #reference>
              <div>{{ "Download" }}</div>
            </template>
            <template #default>
              <div class="terms-container">
                <div
                  class="terms-item"
                  @click="handleGooglePlayClick"
                >
                  {{ "Google Play" }}
                </div>
                <div class="terms-divider"></div>
                <div
                  class="terms-item terms-disable"
                  @click="handleAppStoreClick"
                >
                  {{ "App Store" }}
                </div>
              </div>
            </template>
          </el-popover>
        </div>
      </div>

      <!--      How to loan-->
      <div class="loan-component">
        <div class="component-title">
          <el-image
            class="right-image"
            :src="require('@/assets/images/icon_right.png')"
          ></el-image>
          <div class="title-content">{{ "How to loan" }}</div>
        </div>

        <div class="component-body">
          <div class="body-item">
            <div class="flip-box">
              <div
                :class="{ 'flip-front': playFlip }"
                class="flip-item flip-item-front"
              >
                <div class="loan-container-front">
                  <div class="front-title">
                    {{ "Get Started with Our APP: Your Lucky Start!" }}
                  </div>
                  <div class="front-content">
                    {{
                      "Download the App, register and fill in your personal details to use our lending services."
                    }}
                  </div>
                  <el-image
                    class="front-btn"
                    :src="require('@/assets/images/icon_right_dark.png')"
                  ></el-image>
                </div>
              </div>
              <div
                :class="{ 'flip-back': playFlip }"
                class="flip-item flip-item-back"
              >
                <div class="loan-container-back">
                  <div class="loan-back-bg"></div>
                  <el-image
                    class="loan-image"
                    :src="isReleaseForAndroid ? require('@/assets/images/bg_loan1_android.png') : require('@/assets/images/bg_loan1.png')"
                  ></el-image>
                </div>
              </div>
            </div>
          </div>
          <div class="body-item">
            <div class="flip-box">
              <div
                :class="{ 'flip-front': playFlip }"
                class="flip-item flip-item-front"
              >
                <div class="loan-container-front">
                  <div class="front-title">
                    {{ "Pick Your Loan Amount: Apply For It!" }}
                  </div>
                  <div class="front-content">
                    {{
                      "Choose your desired loan amount. Once approved, we'll transfer the funds to your account immediately"
                    }}
                  </div>
                  <el-image
                    class="front-btn"
                    :src="require('@/assets/images/icon_right_dark.png')"
                  ></el-image>
                </div>
              </div>
              <div
                :class="{ 'flip-back': playFlip }"
                class="flip-item flip-item-back"
              >
                <div class="loan-container-back">
                  <div class="loan-back-bg"></div>
                  <el-image
                    class="loan-image"
                    :src="isReleaseForAndroid ? require('@/assets/images/bg_loan2_android.png') : require('@/assets/images/bg_loan2.png')"
                  ></el-image>
                </div>
              </div>
            </div>
          </div>
          <div class="body-item">
            <div class="flip-box">
              <div
                :class="{ 'flip-front': playFlip }"
                class="flip-item flip-item-front"
              >
                <div class="loan-container-front">
                  <div class="front-title">
                    {{ "Boost Your Credit Score: Repay On Time!" }}
                  </div>
                  <div class="front-content">
                    {{
                      "Stay on track with timely repayments to enhance your credit, helping you achieve more."
                    }}
                  </div>
                  <el-image
                    class="front-btn"
                    :src="require('@/assets/images/icon_right_dark.png')"
                  ></el-image>
                </div>
              </div>
              <div
                :class="{ 'flip-back': playFlip }"
                class="flip-item flip-item-back"
              >
                <div class="loan-container-back">
                  <div class="loan-back-bg"></div>
                  <el-image
                    class="loan-image"
                    :src="require('@/assets/images/bg_loan3.png')"
                  ></el-image>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!--      FAQ-->
      <div class="loan-component">
        <div class="component-title">
          <el-image
            class="right-image"
            :src="require('@/assets/images/icon_right.png')"
          ></el-image>
          <div class="title-content">{{ "FAQ" }}</div>
        </div>

        <div class="faq-body">
          <faq-item
            style="margin-top: 100px"
            index="01"
            title="What are the basic qualifications needed to apply for a loan?"
            content="To be eligible for a loan, you need to be 18 years or older, maintain a valid mobile number, possess a valid Philippine ID, and have a linked bank account or e-wallet."
          ></faq-item>
          <faq-item
            index="02"
            title="What's the maximum loan amount one can get and the loan term offered?"
            content="The maximum loan amount you can receive is 80,000PHP. The duration of your loan could be as long as 180 days."
          ></faq-item>
          <faq-item
            index="03"
            title="How do I receive my loan?"
            content="After completing your basic information, We will guide you to connect your bank card. Please make sure all the information is correct so that you can receive the loan smoothly."
          ></faq-item>
          <faq-item
            index="04"
            title="Am I eligible to apply for a second loan before the first loan is completely settled?"
            content="Before you can proceed with a second loan, your existing loan must be fully repaid. Once that's done, you are free to apply for an additional loan."
          ></faq-item>
          <faq-item
            index="05"
            title="How can I make a payment?"
            content="Our platform cooperates with 100+ platforms such as Gcash, Shopee, BDO, 711, and more, offering repayment options like Bills, QRcode, and direct bank transfers."
          ></faq-item>
        </div>
      </div>

      <!--      Supported Platforms-->
      <div class="loan-component">
        <div class="component-title">
          <el-image
            class="right-image"
            :src="require('@/assets/images/icon_right.png')"
          ></el-image>
          <div class="title-content">{{ "Supported Platforms" }}</div>
          <el-image
            class="license-image"
            :src="require('@/assets/images/icon_license.png')"
          ></el-image>
        </div>

        <div class="faq-body">
          <platform-item style="margin-top: 84px" />
        </div>
      </div>

      <!--      Footer-->
      <footer-item />
    </div>
  </div>

  <el-dialog
    v-model="accountDeletionTipsDialogVisible"
    :show-close="false"
    class="account-deletion-dialog"
  >
    <div class="account-deletion-body">
      <div class="body-left">
        <div class="left-title">{{ "Account Deletion" }}</div>
        <div class="left-content">
          {{ "Please consider carefully before deleting your account." }}
        </div>
      </div>
      <div class="body-right">
        <div
          class="body-close"
          @click="accountDeletionTipsDialogVisible = false"
        >
          <el-image
            style="width: 16px; height: 16px"
            :src="require('@/assets/images/icon_close.png')"
          ></el-image>
        </div>
        <div class="right-title">
          {{ "1. Consequences You Should Be Aware Of:" }}
        </div>
        <div class="right-content">
          {{
            "1）Deleting your account will result in the permanent removal of all associated data, including persona information, loan history, and account details. Please weigh the implications before proceeding\n2）For deleted accounts, we have a 15-day freezing period. After this period can you re-register using the same mobile number."
          }}
        </div>
        <div class="right-title">
          {{
            "2. Before initiating account cancellation, please confirm that:"
          }}
        </div>
        <div class="right-content">
          {{
            "1）There are no unpaid loan orders associated with your account.\n2）No applications are in progress or awaiting disbursement."
          }}
        </div>
        <div class="right-action">
          <el-button
            class="confirm-button"
            @click="handleAccountDeletionContinue"
          >{{ "Continue" }}
          </el-button>
          <el-button
            class="cancel-button"
            @click="accountDeletionTipsDialogVisible = false"
          >{{ "Cancel" }}
          </el-button>
        </div>
      </div>
    </div>
  </el-dialog>

  <el-dialog v-model="smsDialogVisible" :show-close="false" class="sms-dialog" @closed="handleSmsDialogClose()">
    <div class="sms-dialog-body">
      <div
        class="body-close"
        @click="smsDialogVisible = false"
      >
        <el-image
          style="width: 16px; height: 16px"
          :src="require('@/assets/images/icon_close.png')"
        ></el-image>
      </div>
      <div class="body-title">Account Verification</div>
      <el-input
        class="body-phone-no"
        v-model="phoneNoInput"
        placeholder="Ex:9123456789"
        type="text"
        maxlength="10"
      >
        <template #prepend>+63(0)</template>
      </el-input>
      <div class="body-sms-container">
        <el-input
          class="body-phone-no"
          v-model="smsCodeInput"
          :disabled="!isCodeSendBtnEnable || !hasSendSmsCode"
          placeholder="Enter code"
          type="text"
          maxlength="6"
        >
        </el-input>
        <el-button
          class="sms-button"
          :disabled="!isCodeSendBtnEnable || sendCodeCountdown != 0"
          @click="handleSendSmsCode"
        >
          {{
            sendCodeCountdown
              ? `${sendCodeCountdown} s`
              : hasSendSmsCode
                ? "Resend Code"
                : "Send Code"
          }}
        </el-button>
      </div>
      <el-button
        class="confirm-button"
        :disabled="!isConfirmBtnEnable || !isCodeSendBtnEnable"
        @click="handleCheckSmsCode"
      >Confirm
      </el-button>
    </div>
  </el-dialog>

  <el-dialog
    v-model="commonErrorDialogVisible"
    width="30%"
    :show-close="false"
    class="common-error-dialog"
  >
    <div class="common-error-dialog-body">
      <div class="body-close" @click="commonErrorDialogVisible = false">
        <el-image
          style="width: 16px; height: 16px"
          :src="require('@/assets/images/icon_close.png')"
        ></el-image>
      </div>
      <div class="body-title">{{ errorTitle }}</div>
      <div class="body-content">{{ errorMessage }}</div>
      <el-button
        class="confirm-button"
        @click="commonErrorDialogVisible = false"
      >OK
      </el-button>
    </div>
  </el-dialog>

  <el-dialog
    v-model="resultDialogVisible"
    width="620"
    :show-close="false"
    class="result-dialog"
  >
    <div class="result-dialog-body">
      <div class="body-close" @click="resultDialogVisible = false">
        <el-image
          style="width: 16px; height: 16px"
          :src="require('@/assets/images/icon_close.png')"
        ></el-image>
      </div>
      <el-image
        style="width: 304px; height: 277px"
        :src="
          !accountDeletionResult
            ? require('@/assets/images/icon_deletion_failed.png')
            : require('@/assets/images/icon_deletion_success.png')
        "
      ></el-image>
      <div
        class="body-title"
        :class="{ 'failed-title': !accountDeletionResult }"
      >
        {{ errorTitle }}
      </div>
      <div class="body-content">{{ errorMessage }}</div>
      <el-button class="confirm-button" @click="resultDialogVisible = false"
      >OK
      </el-button>
    </div>
  </el-dialog>
</template>

<script lang="ts">
import { computed, onMounted, ref, watch } from "vue";
import router from "@/router";
import { useRoute } from "vue-router";
import FaqItem from "@/components/faqItem.vue";
import PlatformItem from "@/components/platformItem.vue";
import FooterItem from "@/components/footerItem.vue";
import { getPrivacyLink, getRegisterLink, GooglePlayLink, isReleaseForAndroid, isTestEnv } from "@/utils/orderConfig";
import {
  _accountDelete,
  _checkPhoneNext,
  _checkPhoneWeb,
  _login
} from "@/api/help";
import { generateUUID } from "@/utils";

export default {
  name: "homePage",
  components: { FooterItem, PlatformItem, FaqItem },
  setup() {
    const loading = ref(false);
    const loadingDialog = ref(false);
    const route = useRoute();

    const playFlip = ref(false);
    const accountDeletionTipsDialogVisible = ref(false);
    const smsDialogVisible = ref(false);
    const commonErrorDialogVisible = ref(false);

    const resultDialogVisible = ref(false);
    const accountDeletionResult = ref(false);

    const hasSendSmsCode = ref(false);
    const sendCodeCountdown = ref(0);
    const phoneNoInput = ref("");
    const smsCodeInput = ref("");

    const errorMessage = ref("");
    const errorTitle = ref("System Error");
    const homeContainer = ref(null);

    const placeFlag = computed(() => {
      return route.query.place;
    });

    const isCodeSendBtnEnable = computed(() => {
      const phoneRegex = /^9\d{9}$/;
      return (
        phoneNoInput.value.length === 10 && phoneRegex.test(phoneNoInput.value)
      );
    });

    const isConfirmBtnEnable = computed(() => {
      return (
        smsCodeInput.value.length === 6 && /^\d+$/.test(smsCodeInput.value)
      );
    });

    const play = () => {
      playFlip.value = !playFlip.value;
    };

    const handleHomeClick = () => {
      if (homeContainer.value) {
        (homeContainer.value as any).scrollTo({ top: 0, behavior: "smooth" });
      }
    };
    const handleAboutUsClick = () => {
      router.push({
        path: 'aboutUs',
      });
    };
    const handleBlogClick = () => {
      router.push({
        path: 'blog',
      });
    };
    const handleScroll = (event: any) => {
      let scrollTop = event.target.scrollTop;

      const header = document.getElementById("main-header");
      if (scrollTop > 55) {
        header?.classList.add("scrolled");
      } else {
        header?.classList.remove("scrolled");
      }
    };

    const handlePolicyClick = () => {
      window.open(getPrivacyLink(), "_blank");
    };

    const handleRegistrationClick = () => {
      window.open(getRegisterLink(), "_blank");
    };

    const handleGooglePlayClick = () => {
      if (!isTestEnv()) {
        window.open(GooglePlayLink, '_blank');
      }

    };

    const handleAppStoreClick = () => {
      // window.open(AppStoreLink, '_blank')
    };

    const handleAccountDeletion = () => {
      accountDeletionTipsDialogVisible.value = true;
    };

    const handleAccountDeletionContinue = () => {
      accountDeletionTipsDialogVisible.value = false;
      sendCodeCountdown.value = 0;
      smsDialogVisible.value = true;
    };

    const handleSendSmsCode = () => {
      loading.value = true;
      let params = {};
      let formatPhoneNo = 0 + phoneNoInput.value;
      _checkPhoneWeb(params, formatPhoneNo, generateUUID(), {})
        .then((res: any) => {

          let isExist = res.isExist || 0;
          if (isExist == 0) {
            loading.value = false;
            errorTitle.value = "Notice";
            errorMessage.value = "The phone number you filled has not been registered yet.";
            commonErrorDialogVisible.value = true;
          } else if (isExist == 2) {
            loading.value = false;
            errorTitle.value = "Deletion Failed";
            errorMessage.value =
              "You have an unpaid or applying/disbursing order. Resolve the order and try again. For further information, reach out to our customer service.";
            accountDeletionResult.value = false;
            resultDialogVisible.value = true;
          } else {
            // success
            let wybs = res.wybs;
            _checkPhoneNext({}, formatPhoneNo, { "ss": wybs })
              .then((res: any) => {
                loading.value = false;
                hasSendSmsCode.value = true;
                sendCodeCountdown.value = 60;
                startCountdown();
              })
              .catch(err => {
                console.log("_checkPhoneNext", err);
                loading.value = false;
                if (err.code == "-1") {
                  accountDeletionResult.value = false;
                  resultDialogVisible.value = true;
                  errorTitle.value = "SMS verification reach limit";
                  errorMessage.value =
                    "The daily verification cap for this number has been reached. Please try again tomorrow.";
                } else {
                  showCommonErrorDialog();
                  commonErrorDialogVisible.value = true;
                }


              });
          }
        })
        .catch(err => {
          showCommonErrorDialog();
          commonErrorDialogVisible.value = true;
          loading.value = false;
        });
    };

    const startCountdown = () => {
      const time = setInterval(() => {
        sendCodeCountdown.value -= 1;
        if (sendCodeCountdown.value <= 0) {
          clearInterval(time);
        }
      }, 1000);
    };

    const handleSmsDialogClose = () => {

    };

    const resetSmsInput = () => {
      hasSendSmsCode.value = false;
      sendCodeCountdown.value = 0;
      phoneNoInput.value = "";
      smsCodeInput.value = "";
    };

    const showCommonErrorDialog = () => {
      errorTitle.value = "System Error";
      errorMessage.value =
        "Error detected. We're taking measures to fix this. Please try your request again later.";
    };

    const handleCheckSmsCode = () => {
      loading.value = true;
      let formatPhoneNo = 0 + phoneNoInput.value;
      let params = {};

      _login(params, formatPhoneNo, smsCodeInput.value, {})
        .then((res: any) => {
          console.log("_login", res);
          let token = res.item.token;

          let params = {};
          _accountDelete(params, { token: token })
            .then((res: any) => {
              loading.value = false;
              smsDialogVisible.value = false;
              errorTitle.value = "Successful";
              errorMessage.value = res.message;
              accountDeletionResult.value = true;
              resultDialogVisible.value = true;

              resetSmsInput();
            })
            .catch(err => {
              showCommonErrorDialog();
              commonErrorDialogVisible.value = true;
              loading.value = false;
            });
        })
        .catch(err => {
          if (err.code == "-1") {
            errorTitle.value = "Notice";
            errorMessage.value =
              "Wrong verification code, please check and enter it again";
          } else {
            showCommonErrorDialog();
          }

          commonErrorDialogVisible.value = true;
          loading.value = false;
        });
    };

    watch(
      placeFlag, val => {
        if (val == "deletion") {
          handleAccountDeletion();
        }
      },
      { immediate: true }
    );

    watch(
      accountDeletionTipsDialogVisible, val => {
        if (val) {
          if (placeFlag.value != "deletion") {
            router.push({
              path: route.path,
              query: { place: "deletion" }
            });
          }

        } else {
          router.push(route.path);
        }
      },
      { immediate: false }
    );

    onMounted(() => {

    });

    return {
      loading,
      loadingDialog,
      playFlip,
      homeContainer,
      accountDeletionTipsDialogVisible,
      smsDialogVisible,
      errorTitle,
      errorMessage,
      resultDialogVisible,
      accountDeletionResult,
      commonErrorDialogVisible,
      phoneNoInput,
      smsCodeInput,
      isCodeSendBtnEnable,
      isConfirmBtnEnable,
      hasSendSmsCode,
      sendCodeCountdown,
      handleSmsDialogClose,
      handleSendSmsCode,
      handleCheckSmsCode,
      handleAccountDeletionContinue,
      handleAccountDeletion,
      handlePolicyClick,
      handleRegistrationClick,
      handleGooglePlayClick,
      handleAppStoreClick,
      handleHomeClick,
      handleAboutUsClick,
      handleBlogClick,
      handleScroll,
      isReleaseForAndroid
    };
  }
};
</script>

<style lang="scss" scoped>
.list-container {
  height: 100vh;
  width: calc(100% - 240px);
  padding: 0 120px;
  margin: 0 auto;
  position: relative;
  overflow-x: hidden;
  overflow-y: auto;

  .header {
    position: fixed;
    z-index: 10;
    width: calc(1440px - 240px);
    height: 110px;
    transition: all 0.4s;
    background-color: transparent;

    display: flex;
    align-items: center;

    .left-icon {
      display: flex;
      align-items: center;

      .logo {
        width: 50px;
        height: 50px;
      }
    }

    .right-menu {
      flex: 1;

      display: flex;
      justify-content: end;
      align-items: center;

      .menu-group {
        margin: 0 30px;
        height: 50px;
        border-radius: 25px;
        border: 1px solid #4a4a4a;

        font-size: 22px;
        font-weight: 600;
        color: #ffffff;
        line-height: 50px;

        display: flex;
        align-items: center;

        div {
          cursor: pointer;
        }

        .menu-divider {
          height: 21px;
          border-left: 1px solid #4a4a4a;
        }

        .menu-terms {
          padding: 0 31px 0 40px;
        }

        .menu-account {
          padding: 0 20px 0 19px;
        }

        .menu-about-us {
          padding: 0 30px 0 30px;
        }

        .menu-blog {
          padding: 0 40px 0 30px;
        }
      }
    }
  }

  #main-header.scrolled {
    background-color: #141414;
  }

  .list-body {
    padding-top: 135px;

    display: flex;
    flex-direction: column;
    align-items: center;

    .title-image {
      width: 986px;
      height: 186px;
    }

    .bank-image {
      margin-top: 12px;
      width: 611px;
      height: 582px;
    }

    .brief-component {
      margin-top: -98px;
      width: 100%;
      height: 140px;
      backdrop-filter: blur(39px);

      display: flex;
      align-items: center;

      text-align: left;

      position: relative;

      .download-button-new {
        position: absolute;
        top: -76px;
        right: 164px;

        width: 171px;
        height: 50px;
        background: #ffffff;
        border-radius: 8px;

        display: flex;
        align-items: center;
        justify-content: center;

        cursor: pointer;

        font-size: 22px;
        font-weight: 600;
        color: #333333;
        line-height: 30px;
      }

      .brief-item {
        flex: 1;
      }

      .brief-title {
        font-size: 30px;
        font-weight: bold;
        color: #ffffff;
        line-height: 37px;
      }

      .brief-content {
        margin-top: 5px;

        font-size: 26px;
        font-weight: 500;
        color: #ffffff;
        line-height: 40px;
      }
    }

    .loan-component {
      margin-top: 100px;
      width: 100%;

      .component-title {
        display: flex;
        align-items: center;

        .right-image {
          width: 88px;
          height: 64px;
        }

        .title-content {
          flex: 1;
          margin-left: 34px;

          text-align: left;
          font-size: 30px;
          font-weight: bold;
          color: #ffffff;
          line-height: 64px;
        }

        .license-image {
          width: 427px;
          height: 86px;
        }
      }

      .component-body {
        margin-top: 9px;

        width: 100%;
        height: 585px;
        display: flex;
        align-items: end;

        .body-item {
          flex: 1;
          height: 100%;
          padding: 1px;

          .loan-container-front {
            position: absolute;
            top: 134px;
            left: 0;
            right: 0;
            bottom: 0;
            background: #151515;
            border-radius: 20px;
            border: 1px solid #979797;

            display: flex;
            flex-direction: column;
            align-items: start;

            text-align: start;
            padding: 35px 40px 40px 30px;

            .front-title {
              font-size: 30px;
              font-weight: bold;
              color: #ffffff;
              line-height: 46px;
            }

            .front-content {
              flex: 1;
              margin-top: 20px;

              font-size: 22px;
              font-weight: 500;
              color: #ffffff;
              line-height: 40px;
            }

            .front-btn {
              margin-top: 20px;

              width: 56px;
              height: 41px;
            }
          }

          .loan-container-back {
            position: relative;

            .loan-back-bg {
              position: absolute;
              top: 66px;
              left: 0;
              right: 0;
              bottom: 0;
              background: #6e64fa;
              border-radius: 22px;
              border: 1px solid #ffffff;
            }

            .loan-image {
              margin: 0 46px;
            }
          }
        }

        .body-item:not(:last-child) {
          margin-right: 30px;
        }
      }

      .faq-body {
      }
    }

    .flip-box {
      position: relative;
      overflow: hidden;
      width: 100%;
      height: 100%;
    }

    .flip-item {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      padding: 1px;
      transition: all 1s ease-in-out 0s;
      backface-visibility: hidden;
      box-sizing: border-box;
    }

    .flip-item-front {
      z-index: 2;
      color: white;
    }

    .flip-item-back {
      transform: rotateY(180deg);
      z-index: 1;
      color: white;
    }

    .flip-box:hover .flip-item-front {
      transform: rotateY(180deg);
    }

    .flip-box:hover .flip-item-back {
      transform: rotateY(0deg);
    }

    .flip-front {
      transform: rotateY(180deg);
    }

    .flip-back {
      transform: rotateY(0deg);
    }
  }

  .name {
    margin-left: 20px;

    font-size: 20px;
    font-weight: 600;
    color: #ffffff;
    line-height: 50px;
  }
}
</style>