import { createApp } from 'vue';
import App from './App.vue';

import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'

import router from '@/router';
import i18n from '@/assets/lang';
import '@/assets/styles/global.scss';
import { isTestEnv } from '@/utils/orderConfig';


const app = createApp(App);

app.use(ElementPlus)
app.use(i18n);
app.use(router);
app.mount('#app');
