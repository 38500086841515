export const isTestEnv = () => {
  if (location.host.includes('808')) return true;
  // let origindomain = document.domain;
  return false;
};

export const isReleaseForAndroid = true;

//TODO AppStoreLink
export const AppStoreLink = '';

export const GooglePlayLink = isTestEnv() ? '' : "https://play.google.com/store/apps/details?id=com.loan.luckyvip.cash";

export const EmailLink = 'luckyvip.csr@gmail.com';

export const TelLink = '09273758511';

export const FacebookLink = '';

export const getLinkHost = () => {
  let testLinkHost = isReleaseForAndroid ? 'http://47.98.210.212/h5/#/' : 'http://47.98.210.212/h5-ios/#/';

  let linkHost = isReleaseForAndroid ? 'https://api.luckypesovip.com/h5/#/' : 'https://api.luckypesovip.com/h5-ios/#/';

  if (location.host.includes('808') || location.host.includes('120.27.150.99')) {
    linkHost = testLinkHost;
  }

  return linkHost;
};

export const getPrivacyLink = () => {
  return getLinkHost() + 'privacy';
};

export const getRegisterLink = () => {
  return getLinkHost() + 'register';
};

