import { createI18n } from 'vue-i18n'
import zh from './cn/index';
import en from './en/index';
import { langDb, localesLang } from '@/utils';

import { Locale } from 'vant';
// 引入英文语言包
import enUS from 'vant/es/locale/lang/en-US';

Locale.use('en-US', enUS);

let zh_obj = {error: {}};
zh_obj = zh;
let en_obj = {error: {}};
en_obj = en;
const locales = {
  zh: zh_obj,
  en: en_obj
};
const DEFAULT_LANG = localesLang().get() === langDb.cn ? 'zh' : 'en'; //通过浏览器设置的默认语言

const i18n = createI18n({
  legacy: false,
  locale: DEFAULT_LANG,
  allowComposition: true, // you need to specify that!
  messages: locales
});

export const setup = (lang: any) => {
  Object.keys(locales).forEach(lang => {
    document.body.classList.remove(`lang-${lang}`);
  });
  document.body.classList.add(`lang-${lang}`);
  document.body.setAttribute('lang', lang);
};
setup(DEFAULT_LANG);
export default i18n;
