<template>
  <div class="not-found">
    <h1>404 Not Found</h1>
    <p>Sorry, the page you are looking for does not exist.</p>
  </div>
</template>

<script>
export default {

}
</script>

<style scoped>
.not-found {
  background-color: #f5f5f5;
  color: #333;
  text-align: center;
  height: 100vh;
}

h1 {
  font-size: 36px;
  padding-top: 150px;
  margin-bottom: 20px;
}

p {
  font-size: 18px;
  margin-bottom: 20px;
}
</style>
