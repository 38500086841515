import { createRouter, createWebHashHistory } from 'vue-router';
import p404 from '@/views/static/p404.vue';
import { getCookie } from '@/utils';
import homePage from '@/views/authorized/homePage.vue';
import aboutUsPage from "@/views/authorized/aboutUsPage.vue";
import blogPage from "@/views/authorized/blogPage.vue";

const routes = [
  {
    path: '/',
    name: 'home',
    component: homePage,
    meta: {
      authorized: true,
      backgroundColor: '#000000'
    }
  },
  {
    path: '/aboutUs',
    name: 'aboutUs',
    component: aboutUsPage,
    meta: {
      authorized: true,
      backgroundColor: '#ffffff'
    }
  },
  {
    path: '/blog',
    name: 'blog',
    component: blogPage,
    meta: {
      authorized: true,
      backgroundColor: '#ffffff'
    }
  },
  {
    path: '/:pathMatch(.*)*',
    name: '404',
    component: p404,
    meta: {
      authorized: false
    }
  }
];

const router = createRouter({
  history: createWebHashHistory(),
  routes
});

router.beforeEach((to, from, next) => {
  let token: any;
  token = getCookie();

  next();
});

export default router;
