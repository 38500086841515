import { Base64 } from 'js-base64';
import Icookie from 'cookiejs';
import CryptoJS from 'crypto-js';

export const isMobile = (() => {
  let UA = navigator.userAgent;
  let Sys = {
    ios: !!UA.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/), // ios终端
    android: UA.indexOf('Android') > -1 || UA.indexOf('Adr') > -1 // android终端
  };
  return Sys.ios || Sys.android;
})();

export const types = (val: any) => {
  return typeof val;
};

export const isObject = (val: any) => {
  return types(val) === 'object';
};

// 是否布尔值
export const isBoolean = (val: any) => {
  return types(val) === 'boolean';
};
// 判断是否为字符
export const isString = (val: any) => {
  return types(val) === 'string';
};
// 判断是否为方法
export const isFunction = (val: any) => {
  return types(val) === 'function';
};
// 判断是否数字
export const isNumber = (val: any) => {
  return types(val) === 'number';
};

// isObjectString
export const isObjectString = (val: any) => {
  return isString(val) && /^(\{.*\}|\[.*\])$/.test(val);
};

// 强制为数字类型
export const toNumber = (num: any) => {
  return parseFloat(num);
};

// 判断是否为数组
export const isArray = (val: any): any => {
  return (types(val) as any) === 'array';
};

/**
 *判断是否是数字
 *
 **/

export const isRealNum = (val: any) => {
  // isNaN()函数 把空串 空格 以及NUll 按照0来处理 所以先去除，
  if (val === '' || val == null) {
    return false;
  }
  return !isNaN(val);
};

//  格式化时间
export const dateFormat = (dateNumber: any, format = 'yyyy-MM-dd HH:mm:ss') => {
  if (dateNumber.toString().includes('-')) {
    dateNumber = dateNumber.replace(/-/g, '/');
    dateNumber = dateNumber.toString().substr(0, 19);
  }
  let date = new Date(dateNumber);
  // date = new Date(toNumber((date.toString() + '0000000000000').substr(0, 13)));
  let y: string = String(date.getFullYear());
  let M: string = String(date.getMonth() + 1);
  let d: string = String(date.getDate());
  let h: string = String(date.getHours());
  let m: string = String(date.getMinutes());
  let s: string = String(date.getSeconds());
  format = format.replace('yyyy', y);
  format = format.replace('MM', coverData(M));
  format = format.replace('M', M);
  format = format.replace('dd', coverData(d));
  format = format.replace('d', d);
  format = format.replace('HH', coverData(h));
  format = format.replace('H', h);
  format = format.replace('mm', coverData(m));
  format = format.replace('m', m);
  format = format.replace('ss', coverData(s));
  format = format.replace('s', s);
  return format;
};

export const formatDate = (timestamp: number) => {
  if(!timestamp) return timestamp;
  const date = new Date(timestamp);

  const monthAbbreviation = new Intl.DateTimeFormat('en-US', { month: 'short' }).format(date);

  const day = date.getDate();
  const year = date.getFullYear();

  return `${monthAbbreviation}-${day}-${year}`;
}

export const formatDateTime = (timestamp: number) => {
  if(!timestamp) return timestamp;
  const date = new Date(timestamp);

  const monthAbbreviation = new Intl.DateTimeFormat('en-US', { month: 'short' }).format(date);
  const dayAbbreviation = new Intl.DateTimeFormat('en-US', { day: '2-digit' }).format(date);
  const year = date.getFullYear();

  const hoursAbbreviation = new Intl.DateTimeFormat('en-US', { hour: '2-digit', hour12: false }).format(date);
  const minuteAbbreviation = new Intl.DateTimeFormat('en-US', { minute: '2-digit' }).format(date);
  const secondsAbbreviation = new Intl.DateTimeFormat('en-US', { second: '2-digit'}).format(date);


  return `${monthAbbreviation}-${dayAbbreviation}-${year} ${hoursAbbreviation}:${minuteAbbreviation}:${coverData(secondsAbbreviation)}`;
}

export const coverData = (obj = '', n = 2) => {
  obj = obj.toString();
  if (obj.length >= n) {
    return obj;
  }
  obj = '0000000000' + obj;
  return obj.substr(obj.length - n);
};

// localStorage 操作
export const store = (name: any) => {
  let __store: any = window.localStorage;
  let store = __store.getItem(name);
  try {
    store = store ? Base64.decode(store) : store;
  } catch (err) {
    store = '';
  }

  return {
    set: (data: any) => {
      data = Base64.encodeURI(
        isObject(data) || isArray(data) ? JSON.stringify(data) : data
      );
      __store.setItem(name, data);
    },
    get: () => (isObjectString(store) ? JSON.parse(store) : store),
    remove: () => __store.removeItem(name)
  };
};

const cookieName = 'x-api-token';
export const clearCookie = function () {
  cookie(cookieName).remove();
};
export const getCookie = function () {
  return cookie(cookieName).get();
};

const credentialName = 'x-api-credential';
export const clearCredential = function () {
  cookie(credentialName).remove();
};
export const getCredential = function () {
  return cookie(credentialName).get();
};

const keyName = 'x-api-key';
export const clearKey = function () {
  cookie(keyName).remove();
};
export const getKey = function () {
  return cookie(keyName).get();
};

// cookie 操作
export const cookie = function (name: any) {
  return {
    get: function () {
      return name ? Icookie.get(name) : Icookie.all();
    },
    set: function (data: any) {
      return Icookie.set(name, data);
    },
    remove: function () {
      return name ? Icookie.remove(name) : Icookie.clear();
    }
  };
};

export const langDb = {
  en: 'en-US',
  cn: 'zh-CN'
};

// 修改和获取
export const localesLang = () => {
  const localesLang = 'localesLang';
  let lang = langDb.en;
  return {
    change: () => {
      lang === 'zh-CN'
        ? store(localesLang).set(langDb.en)
        : store(localesLang).set(langDb.cn);
    },
    get: () => lang
  };
};

const generateRandomIv = () => {
  const randomBytes = CryptoJS.lib.WordArray.random(32)
  return randomBytes;
}

export const encrypt = (word: any, serverKey: string) => {
  let encrypted = "";
  let key = CryptoJS.enc.Utf8.parse(serverKey);
  let iv = generateRandomIv();
  if (typeof word == "string") {
    const srcs = CryptoJS.enc.Utf8.parse(word);

    encrypted = CryptoJS.AES.encrypt(srcs, key, {
      mode: CryptoJS.mode.CBC,
      padding: CryptoJS.pad.Pkcs7
    }).toString();
  } else if (typeof word == "object") {
    //对象格式的转成json字符串
    const data = JSON.stringify(word);
    const srcs = CryptoJS.enc.Utf8.parse(data);
    encrypted = CryptoJS.AES.encrypt(srcs, key, {
      iv: iv,
      mode: CryptoJS.mode.ECB,
      padding: CryptoJS.pad.Pkcs7
    }).toString();
  }
  return encrypted;
};

export const decrypt = (word: string, serverKey: string) => {
  let key = CryptoJS.enc.Utf8.parse(serverKey);
  const decrypt = CryptoJS.AES.decrypt(word, key, {
    mode: CryptoJS.mode.ECB,
    padding: CryptoJS.pad.Pkcs7
  });
  const decryptedStr = decrypt.toString(CryptoJS.enc.Utf8);
  return decryptedStr.toString();
}

export const generateUUID = () => {
  var d = new Date().getTime(); //Timestamp
  var d2 = (performance && performance.now && (performance.now()*1000)) || 0; //Time in microseconds since page-load or 0 if unsupported
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
    var r = Math.random() * 16; //random number between 0 and 16
    if(d > 0) { //Use timestamp until depleted
      r = (d + r)%16 | 0;
      d = Math.floor(d/16);
    } else { //Use microseconds since page-load if supported
      r = (d2 + r)%16 | 0;
      d2 = Math.floor(d2/16);
    }
    return (c === 'x' ? r : (r & 0x3 | 0x8)).toString(16);
  });
}

