import axios from '../utils/request';

export const _checkPhoneWeb = (data: any, phoneNumber: string, uuid: string, headers: any) => {
  return axios.request({
    url: `/gw/Dox0/kKiLMOjmioGXm2BQ?phone=${phoneNumber}&uuid=${uuid}`,
    method: 'post',
    data,
    headers
  });
};

export const _checkPhoneNext = (data: any, phoneNumber: string, headers: any) => {
  return axios.request({
    url: `/gw/uW80/spEsblscivYfboFW?phone=${phoneNumber}&sms_useage=${10}`,
    method: 'post',
    data,
    headers
  });
};

export const _login = (data: any, phoneNumber: string, smsCode: string, headers: any) => {
  return axios.request({
    url: `/gw/QA9p/NxhtqXq8xQt6WReC?username=${phoneNumber}&smsCode=${smsCode}`,
    method: 'post',
    data,
    headers
  });
};

export const _accountDelete = (data: any, headers: any) => {
  return axios.request({
    url: `/gw/Obs8/c6zUzG76Oo3TXyMD?isDelete=1`,
    method: 'post',
    data,
    headers
  });
};
