<template>
  <div class="item-container" v-loading="loading">
    <div class="item-index">{{ index }}</div>
    <div class="item-body">
      <div class="item-title">{{ title }}</div>
      <div class="item-content" v-if="isExpand">{{ content }}</div>
    </div>

    <el-image
      class="item-btn"
      @click="troggleExpand"
      :src="
        isExpand
          ? require('@/assets/images/icon-expand.png')
          : require('@/assets/images/icon_short.png')
      "
    ></el-image>
  </div>
</template>

<script lang="ts">
import { ref } from 'vue';
import { useRoute } from 'vue-router';
import { useI18n } from 'vue-i18n';

export default {
  name: 'faqItem',
  props: {
    index: '',
    title: '',
    content: ''
  },
  setup() {
    const loading = ref(false);
    const route = useRoute();
    const { t } = useI18n();

    const isExpand = ref(false);

    const troggleExpand = () => {
      isExpand.value = !isExpand.value;
    };

    return {
      loading,
      isExpand,
      troggleExpand
    };
  }
};
</script>

<style lang="scss" scoped>
.item-container {
  display: flex;
  margin-top: 30px;
  padding: 40px 0;

  .item-index {
    font-size: 58px;
    font-weight: bold;
    color: #9be557;
    line-height: 60px;
  }

  .item-body {
    flex: 1;
    text-align: left;
    margin-left: 36px;
    margin-top: 4px;

    .item-title {
      font-size: 30px;
      font-weight: bold;
      color: #ffffff;
      line-height: 54px;
    }

    .item-content {
      margin-top: 24px;

      font-size: 26px;
      font-weight: 500;
      color: #ffffff;
      line-height: 50px;
    }
  }

  .item-btn {
    width: 72px;
    height: 72px;

    cursor: pointer;
  }
}
</style>