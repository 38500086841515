<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { useRoute } from "vue-router";
import { computed, onMounted, ref, watch } from "vue";

export default defineComponent({
  name: "App",
  components: {},
  setup() {
    const route = useRoute();

    const setBodyBackgroundColor = (color: any) => {
      document.body.style.backgroundColor = color || "#000000";
    };

    onMounted(() => {
      setBodyBackgroundColor(route.meta.backgroundColor);
    });

    watch(
      () => route.meta.backgroundColor,
      (newColor) => {
        setBodyBackgroundColor(newColor);
      }
    );
    return {};
  }
});
</script>

<style lang="scss">
#app {
  width: $max-width;
  margin: 0 auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
}
</style>
